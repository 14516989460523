import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'

const service = axios.create({
    // baseURL: 'http://192.168.31.67:6001',
    baseURL: `https://uat.rby.show/`,
    timeout: 15000, // request timeout
    responseType: "json",
    withCredentials: true, // 跨域请求是否要携带cookie
    headers: {
        'Content-Type': 'application/json',
    }
})

/** 添加请求拦截器 **/
service.interceptors.request.use(
    config => {
        if (localStorage.getItem('RbyToken') == null) {
            // window.location.href = '/loginPage'
        } else {
            config.headers['RbyToken'] = localStorage.getItem('RbyToken')
        }

        return config
    },
    error => {
        Message.error("操作失败！")
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        if (response.data.errCode == 304) {
            Message.error('登录已过期,请重新登录')
            router.push('/')
            localStorage.clear()
        } else {
            return Promise.resolve(response)
        }
    },
    error => {
        Message.error("操作失败！")
        return Promise.resolve(error)
    }
)

export default service