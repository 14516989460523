import CryptoJS from 'crypto-js/crypto-js' //引入js

function Decrypt(word) {//加密
    var key = CryptoJS.enc.Utf8.parse(`KDD${localStorage.getItem('times').toString()}`);//key要与后端保持一致
    var decrypted = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted;
}

function Encrypt(word) {//解密
    var key = CryptoJS.enc.Utf8.parse(`KDD${localStorage.getItem('times').toString()}`);//key要与后端保持一致
    let encrypted = CryptoJS.AES.encrypt(word, key, { 
        mode: CryptoJS.mode.ECB, 
        padding: CryptoJS.pad.Pkcs7 
    });
    return encrypted.toString()
}
export{
    Decrypt,
    Encrypt
}
