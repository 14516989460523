import axios from "@/http/axios"


// 登录 
export const login = (data) => axios.post('/system/api/system/visitor/login', data)

// 获取验证码
export const sendCode = (phone) => axios.get(`/system/api/system/visitor/sendCode?phone=` + phone)

// 获取主播列表 
export const getAnchorList = (data) => axios.get(`live/api/live/video/getAnchorList?name=${data}`,
    {
        headers: { "RbyToken": localStorage.getItem('RbyToken') }
    })



// 获取直播序列 
export const getXunData = (data) => axios.post('live/api/live/video/getXunData', data)
