<template>
  <div class="content">
    <div id="login" class="videoDown">
      <h2>登陆\注册</h2>
      <div class="login_form">
        <div class="item">
          <div class="title">手机号：</div>
          <div class="right">
            <el-input
              @input="yanzheng(0)"
              v-model="phone"
              placeholder="请输入手机号"
            ></el-input>
          </div>
        </div>
        <p v-if="phoneErr" class="error">
          {{ phone.length != 11 ? "请输入正确的手机号" : "" }}
        </p>
        <div class="item">
          <div class="title">验证码：</div>
          <div class="right">
            <el-input
              @input="yanzheng(1)"
              v-model="yzm"
              placeholder="请输入手机号"
            ></el-input>
            <el-button @click="sendCode">{{
              countdown == 60 ? "获取验证码" : `${countdown}`
            }}</el-button>
          </div>
        </div>
        <p v-if="yzmErr" class="error">验证码不能为空</p>
      </div>
      <div class="btn">
        <el-button class="login_btn" @click="login">
          登 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录
        </el-button>
        <p v-if="loginErr" class="error">登录失败</p>
      </div>
    </div>
  </div>
</template>

<script>
import { login, sendCode } from "@/api/index";

export default {
  name: "Login",
  data() {
    return {
      phoneErr: false,
      yzmErr: false,
      loginErr: false,
      phone: "",
      yzm: "",
      countdown: 60,
    };
  },
  methods: {
    // 判断输入框是否有值
    yanzheng(e) {
      if (e == 0) {
        this.phoneErr = false;
      } else {
        this.yzmErr = false;
      }
    },
    login() {
      if (this.phone == "") {
        this.phoneErr = true;
        return;
      } else if (this.yzm == "") {
        this.yzmErr = true;
        return;
      }
      let data = {
        phone: this.phone,
        code: this.yzm,
      };
      login(data)
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("RbyToken", res.data.data.RbyToken);
            localStorage.setItem("USERID", res.data.data.userInfo.userId);
            localStorage.setItem("userName", res.data.data.userInfo.username);
            this.$router.push('/console')
          } else {
            this.$message.error(res.data.errMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    settime() {
      if ((this.phone == "" || this.phone.length != 11)) {
        this.phoneErr = true;
      } else {
        if (this.countdown == 0) {
          this.countdown = 60;
          return;
        } else {
          this.countdown--;
        }
        var that = this;
        setTimeout(() => {
          that.settime();
        }, 1000);
      }
    },
    // 获取验证码
    sendCode() {
      sendCode(this.phone)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.settime();
          } else {
            this.$message.error(res.data.errMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
  margin: 0;
  height: 100vh;
  display: flex;
  /*justify-content: center;*/
  background-image: url("https://robot-rby.oss-cn-hangzhou.aliyuncs.com/LIVE/project-img/easyVersion/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  ::v-deep .el-input__inner {
    border-radius: 0;
  }

  align-items: center;
  #login {
    text-align: center;
    margin: 0 auto;
    width: 30%;
    height: 520px;
    background-image: url("https://robot-rby.oss-cn-hangzhou.aliyuncs.com/LIVE/project-img/easyVersion/bg(1).png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    h2 {
      margin-top: 40px;
      color: aliceblue;
      font-weight: 100;
    }

    .login_form {
      padding: 20px;
      .title {
        width: 15%;
        color: #fff;
        font-size: 18px;
        font-weight: 100;
      }
      ::v-deep .el-input__inner {
        width: 100%;
        background-color: transparent;
        padding: 2px;
        text-indent: 2px;
        color: white;
        font-size: 20px;
        height: 50px;
        box-sizing: border-box;
        border: 0;
        background-color: #0d4590;
        border: 1px solid #52abe5;
      }
      .error {
        width: 70%;
        color: red;
        margin: 0;
        margin-top: 10px;
      }
      .item {
        display: flex;
        align-items: center;
        margin: 30px 0px 0px 5px;
        .right {
          width: 80%;
          display: flex;
          align-items: center;
          ::v-deep .el-button {
            width: 35%;
            margin: 0;
            box-sizing: border-box;
            background-color: #00d2ff;
            border: 1px solid #00d2ff;
            padding: 10px;
            height: 50px;
            font-size: 18px;
            color: #000;
            font-weight: 600;
            border-radius: 0;
          }
        }
      }
    }
    input::placeholder {
      color: #67a1f2;
      font-weight: 100;
      font-size: 18px;
    }

    .login_btn {
      background-color: #00d2ff;
      border: 1px solid #00d2ff;
      padding: 10px;
      width: 80%;
      height: 60px;
      font-size: 24px;
      color: #000;
      font-weight: bold;
      margin-top: 10%;
    }
    .btn .error {
      width: 80%;
      margin: 0 auto;
      margin-top: 10px;
      color: red;
    }
  }
}
</style>
