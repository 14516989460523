<template>
  <div class="heade">
    <div class="header_left"></div>
    <div class="header_right">
      <img
        class="headPortrait"
        src="https://robot-rby.oss-cn-hangzhou.aliyuncs.com/LIVE/project-img/助播系统UI图/Desktop - 20/Ellipse 3@2x.png"
        alt=""
      />
      <el-dropdown trigger="click" class="name">
        <span class="el-dropdown-link">
          <div>
            <div>{{ userName }}</div>
          </div>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="!md">
            <div @click="userManual">使用手册</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="logOut">退出登录</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoTutorialShow: false,
      userName: "",
      dialogVisible: false,
      newPassword: "",
      surePassword: "",
    };
  },
  props: {
    // name: {
    //   type: String,
    //   default: localStorage.getItem("userName"),
    // },
    gos: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.getUserName();
  },
  methods: {
    //用户手册
    userManual() {
      this.$router.push({
        path: "/md",
      });
    },
    // 获取名字
    getUserName() {
      this.$data.userName = localStorage.getItem("userName");
    },
    // 退出登录
    logOut() {
      localStorage.clear();
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heade {
  width: 100%;
  height: 70px;
  padding: 0 100px;
  box-sizing: border-box;
  background-color: #2f3346;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_left {
  width: 220px;
  height: 50px;
  background-image: url("https://robot-rby.oss-cn-hangzhou.aliyuncs.com/LIVE/visitorElf/img/logo%402x.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.header_right {
  display: flex;
  align-items: center;
  height: 35px;
}

.header_right .headPortrait {
  width: 40px;
  height: 40px;
}

.header_right .name {
  position: relative;
  font-size: 14px;
  color: #fff;

  margin: 0 10px;
}
.el-icon-arrow-down {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}
.version {
  font-size: 0.6rem;
  color: #555555;
}
.header_right .right {
  height: 35px;
  display: flex;
  align-items: center;
}

.header_right .icon {
  width: 10px;
  height: 10px;
}

.el-dropdown-menu {
  background: #202223;
  border: 1px solid #202223;
}

.el-dropdown-menu__item {
  color: #fff;
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #3b3e3f;
  color: #fff;
}

.ite {
  margin-bottom: 20px;
}

.ite p {
  font-size: 16px;
  margin-bottom: 10px;
}
.videoTutorial {
  position: relative;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  height: 100vh;
  z-index: 99999999999999;
  background: rgba(0, 0, 0, 0.3);
}
.videoTutorial video {
  /* object-position: center;
  object-fit: cover; */
  /* width: 50vw; */
  height: 55vh;
}
.relativeVideo {
  position: relative;
}
.ion {
  position: absolute;
  top: 0;
  right: -2.2rem;
  transform: translateY(-80%);
}
.ion img {
  width: 2rem;
  cursor: pointer;
}
</style>
