<template>
  <div class="content">
    <Head />
    <div class="nav">
      <div class="left">
        <div class="left_header">实时预览</div>
        <div class="left_nav1">
          <div class="left_nav" @click="upLoad" v-if="videoUrl == ''">
            <img
              src="https://robot-rby.oss-cn-hangzhou.aliyuncs.com/LIVE/visitorElf/img/添加 (1)@2x.png"
              alt=""
            />
            <p>点击选择直播素材</p>
          </div>
          <video
            id="video"
            class="leftVideo"
            v-if="videoUrl != ''"
            :src="videoUrl"
          ></video>
        </div>
      </div>
      <div class="right">
        <div class="right_nav">
          <div class="right_nav_left">
            <div class="right_nav_left_head">选择主播</div>
            <div class="right_nav_left_list">
              <div
                class="right_nav_left_item"
                v-for="(item, index) in anchorList"
                :key="index"
                @click="checkAnchor(index)"
              >
                <img
                  :style="{
                    border: anchorInd == index ? '4px solid #6dc9f5' : '',
                  }"
                  :src="item.anchorInfo.headPortrait"
                  alt=""
                />
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div class="right_nav_right">
            <div class="right_nav_right_head">当前主播</div>
            <div class="right_nav_right_list" v-if="anchorInd != -1">
              <img
                :src="anchorList[anchorInd].anchorInfo.headPortrait"
                alt=""
              />
              <div class="title">姓名: {{ anchorList[anchorInd].name }}</div>
              <div class="title">年龄: {{ anchorList[anchorInd].anchorInfo.age }}</div>
              <div class="title">性格: 活泼开朗</div>
              <el-button type="primary" @click="addAnchor">添加主播</el-button>
            </div>
          </div>
        </div>
        <div class="right_foot">
          <div class="right_foot_head">直播列表</div>
          <div class="right_foot_list">
            <div
              class="right_foot_item"
              v-for="(item, index) in liveList"
              :key="index"
            >
              <div class="img" :style="`background-image:url('${item.anchorInfo.headPortrait}')`">
                <div class="num">{{ index + 1 }}</div>
              </div>
              <div class="name">{{ item.name }}</div>
              <div class="btn" @click="delAnchor(index)">删除</div>
            </div>
          </div>
        </div>
        <div class="right_btn">
          <el-button type="primary" @click="start">开始直播</el-button>
        </div>
      </div>
    </div>
    <el-upload
      style="display: none"
      action=""
      :limit="1"
      :on-change="handleChange"
      :auto-upload="false"
      :show-file-list="false"
    >
      <el-button size="small" type="primary" id="upload">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import Head from "@/components/header";
import { getAnchorList } from "@/api/index";
export default {
  components: {
    Head,
  },
  data() {
    return {
      anchorList: [],
      anchorInd: -1,
      liveList: [],
      videoUrl: "",
      errorTime: 0.05,
    };
  },
  mounted() {
    let videoHeight = document.querySelector(".left_nav1 ").offsetHeight;
    document.querySelector(".left_header").style.width =
      videoHeight * (9 / 16) + "px";
    document.querySelector(".left_nav1").style.width =
      videoHeight * (9 / 16) + "px";
    localStorage.setItem("play", "false")
  },
  methods: {
    // 获取主播列表
    getAnchorList(name) {
      getAnchorList(name)
        .then((res) => {
          this.anchorList = res.data.data;
          this.anchorList.forEach((e) => {
            e.img =
              "https://robot-rby.oss-cn-hangzhou.aliyuncs.com/LIVE/project-img/助播系统UI图/Desktop - 20/Ellipse 3@2x.png";
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 主播选择
    checkAnchor(e) {
      this.anchorInd = e;
      var video = document.getElementById("video");
      video.currentTime = this.anchorList[e].startTime + this.errorTime;
      console.log(this.anchorList[e].startTime);
    },
    // 添加主播
    addAnchor() {
      if (this.liveList.length == 0) {
        this.liveList.push(this.anchorList[this.anchorInd]);
      } else {
        let num = 0;
        this.liveList.forEach((e) => {
          if (e.id != this.anchorList[this.anchorInd].id) {
            num = 0;
          } else {
            num += 1;
          }
        });
        if (num == 0) {
          this.liveList.push(this.anchorList[this.anchorInd]);
        } else {
          this.$message.error("主播已存在，请重新选择");
        }
      }
    },
    // 主播删除
    delAnchor(e) {
      this.liveList.splice(e, 1);
    },
    // 开始直播
    start() {
      if (this.liveList.length == 0) {
        this.$message.error("请先选择主播");
      } else if (localStorage.getItem("play") === "true") {
        this.$message.error("正在直播中");
      } else {
        localStorage.setItem("list", JSON.stringify(this.liveList));
        let routeUrl = this.$router.resolve({
          path: "/play",
          query: {}
        })
        // this.$router.push("/play");
        window.open(routeUrl.href, '_blank')
      }
    },
    upLoad() {
      document.getElementById("upload").click();
    },
    handleChange(file, fileLists) {
      this.getAnchorList(file.name);
      this.videoUrl = URL.createObjectURL(file.raw);
      localStorage.setItem('videoUrl',this.videoUrl)
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100vh;
  color: #fff;
  background-color: #292f3b;
  .nav {
    height: calc(100vh - 100px);
    padding: 25px 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .left {
      border-radius: 20px;
      background-color: #3b4158;
      position: relative;
      .left_header {
        height: 100px;
        line-height: 100px;
        font-size: 28px;
        padding-left: 30px;
        box-sizing: border-box;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background-color: #353b54;
        box-shadow: 0px 1px 0px 0px rgba(113, 128, 177, 0.4);
      }
      .left_nav1 {
        width: 100%;
        height: calc(100% - 100px);
        .left_nav {
          width: 140px;
          height: 140px;
          text-align: center;
          position: absolute;
          top: calc(50% - 70px);
          left: calc(50% - 70px);
          img {
            width: 70px;
          }
          p {
            color: #7480ad;
          }
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
    .right {
      width: 75%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .right_nav {
        width: 100%;
        height: 55%;
        background-color: #34394e;
        border-radius: 20px;
        display: flex;
        .right_nav_left {
          width: 70%;
          .right_nav_left_head {
            height: 15%;
            font-size: 1.25rem;
            padding-left: 40px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #4e5777;
          }
          .right_nav_left_list {
            width: 100%;
            height: 85%;
            overflow: auto;
            padding: 5%;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            &::-webkit-scrollbar {
              display: none;
            }
            .right_nav_left_item {
              width: 23%;
              margin-right: 2%;
              margin-bottom: 3%;
              text-align: center;
              img {
                width: 60%;
                margin-bottom: 10px;
                box-sizing: border-box;
                border-radius: 50%;
                border: 4px solid #7480ad;
              }
              .name {
                width: 100%;
                color: #a8b3d8;
              }
            }
          }
        }
        .right_nav_right {
          width: 30%;
          height: 100%;
          overflow: auto;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          background-color: #3a4158;
          .right_nav_right_head {
            height: 15%;
            font-size: 1.25rem;
            padding-left: 40px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          }
          .right_nav_right_list {
            width: 100%;
            height: 85%;
            overflow: auto;
            &::-webkit-scrollbar {
              display: none;
            }
            text-align: center;
            img {
              width: 30%;
              border-radius: 50%;
              border: 4px solid #7480ad;
              margin: 5% 0;
            }
            .title {
              margin-bottom: 5%;
            }
            ::v-deep .el-button {
              border: 1px solid #3357ff;
              background-color: #3357ff;
            }
          }
        }
      }
      .right_foot {
        height: 30%;
        background-color: #34394e;
        border-radius: 20px;
        .right_foot_head {
          height: 30%;
          font-size: 1.25rem;
          padding-left: 40px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #4e5777;
        }
        .right_foot_list {
          width: 100%;
          height: 70%;
          padding: 0 5%;
          padding-top: 2%;
          box-sizing: border-box;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          &::-webkit-scrollbar {
            display: none;
          }
          .right_foot_item {
            width: 16%;
            margin-bottom: 3%;
            text-align: center;
            .img {
              width: 70px;
              height: 70px;
              border: 3px solid #7480ad;
              border-radius: 50%;
              box-sizing: border-box;
              overflow: hidden;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              position: relative;
              left: calc(50% - 35px);
              .num {
                width: 100%;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.6);
                position: absolute;
                bottom: 0;
              }
            }
            .name {
              margin: 5% 0;
            }
            .btn {
              width: 50%;
              height: 25px;
              line-height: 25px;
              margin: 0 auto;
              font-size: 14px;
              color: #818baa;
              background: #464f6c;
              border-radius: 4px;
            }
          }
        }
      }
      .right_btn {
        width: 100%;
        text-align: right;
        ::v-deep .el-button {
          border: 1px solid #3357ff;
          background-color: #3357ff;
        }
      }
    }
  }
}
</style>