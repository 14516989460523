<template>
  <div class="content">
    <video id="video" class="main" :src="videoUrl"></video>
  </div>
</template>

<script>
import { Decrypt, Encrypt } from "@/assets/aes";
import { getXunData } from "@/api/index";
export default {
  data() {
    return {
      videoUrl: "",
      videoList:[],
      liveList: [],
      index:0,
      index1:0,
      times:'',
      errorTime: 0.05,
    };
  },
  mounted() {
    this.init();
    this.videoUrl = localStorage.getItem("videoUrl");
    this.liveList = JSON.parse(localStorage.getItem("list"));
    this.start();
  },
  created() {
    window.addEventListener("resize", this.init);
  },
  methods: {
    init() {
      let width = document.body.clientWidth;
      let height = document.body.clientHeight;
      var x = width / 1080 < height / 1920 ? width / 1080 : height / 1920;
      document.querySelector(".main").style.width = 1080 * x + "px";
      document.querySelector(".main").style.height = 1920 * x + "px";
    },
    // 开始直播
    start() {
      console.log(this.liveList);
      if (this.liveList === undefined || this.liveList === null) {
        this.$message.error("请选择主播后重新开播");
        return;
      }
      localStorage.setItem("play", "true");
      var dates = +new Date();
      localStorage.setItem("times", dates);
      let data = {
        name: this.liveList[this.index].name, //资源包名称
        anchorId: this.liveList[this.index].id, //主播ID
        shifting: this.changes(dates.toString()), //验签
        userId: localStorage.getItem("USERID"), //用户id
        xTime: 5
      };
      getXunData(data)
        .then((res) => {
            let data = Decrypt(res.data.data)
            let list = JSON.parse(data)
          this.bofang(list)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bofang(list){
        this.index1 = 0
        this.videoList = []
        list.startSingleRhythmData.forEach(e => {
            this.videoList.push(e)
        });
        list.amongSingleRhythmData.forEach(e => {
            this.videoList.push(e)
        });
        // console.log(this.videoList);
        let video = document.getElementById('video');
        console.log("当前播放主播：" + this.liveList[this.index].name)
        console.log("当前播放片段：" + this.videoList[this.index1].id)
        video.currentTime = Number(this.videoList[this.index1].startTime) - Number(localStorage.getItem('times'))
            + Number(this.liveList[this.index].startTime) + this.errorTime;
        video.play();
        this.times = setInterval(() => {
            this.checkTime()
        }, 40);
    },
    // 校验是否结束
    checkTime(){ 
        let video = document.getElementById('video')
        let videoTime = video.currentTime
        let endTime = Number(this.videoList[this.index1].endTime) - Number(localStorage.getItem('times'))
            + Number(this.liveList[this.index].startTime);
        if(endTime - videoTime < 0.04) {
            if (this.index1 < this.videoList.length - 1) {
              this.index1 += 1;
              console.log("当前播放片段：" + this.videoList[this.index1].id)
              video.currentTime = Number(this.videoList[this.index1].startTime) - Number(localStorage.getItem('times'))
              + Number(this.liveList[this.index].startTime) + this.errorTime;
              video.play();
            } else if (this.index < this.liveList.length - 1) {
              this.index += 1;
              clearInterval(this.times);
              video.pause();
              this.start();
            } else {
              console.log("直播结束");
              video.pause();
              clearInterval(this.times);
              localStorage.removeItem("times");
              localStorage.removeItem("list");
              localStorage.setItem("play", "false");
            }

        }
    },
    // 时间戳
    changes(str) {
      var str1 = "";
      var str2 = "";
      for (var i = 0; i < str.length; i++) {
        if (i % 2 == 0) {
          str2 += str[i];
        } else {
          str1 += str[i];
        }
      }
      return str1 + "-" + str2;
    },
  },
};
</script>
<style>
body {
  background-color: #01FF02;
}
</style>
<style lang="scss" scoped>
.content {
  height: 100vh;
}
</style>