import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login.vue'
import Console from '@/views/console.vue'
import Play from '@/views/play.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Login,
    children: [{
      path: '/',
      component: () =>
        import('@/views/login')
    },]
  },
  {
    path: '/console',
    component: Console,
    children: [{
      path: '/',
      component: () =>
        import('@/views/console')
    },]
  },
  {
    path: '/play',
    component: Play,
    children: [{
      path: '/',
      component: () =>
        import('@/views/play')
    },]
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
